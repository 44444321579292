import React from "react";
import "./styles.css";

import rfnsa from "../../images/partners/rfnsa.png";
import msdigital from "../../images/partners/msdigital.png";
import brdigital from "../../images/partners/brdigital.png";
import alt from "../../images/partners/alt.png";
import oggi from "../../images/partners/oggi.png";
import transpetro from "../../images/partners/transpetro.png";
import acelera from "../../images/partners/acelera.png";
import alfa from "../../images/partners/alfa.png";
import fenix from "../../images/partners/fenix.png";
import claro from "../../images/partners/claro.png";
import sim from "../../images/partners/sim.png";
import conbras from "../../images/partners/conbras.png";
import vett from "../../images/partners/vett.png";
import vivo from "../../images/partners/vivo.png";
import tim from "../../images/partners/tim.png";
import elo from "../../images/partners/elo.png";
import glamour from "../../images/partners/glamour.png";
import nesic from "../../images/partners/nesic.png";
import vital from "../../images/partners/vital.png";
import Tecpar from "../../images/partners/tecpar.jpg";

const partnersData = [
  { id: 1, logo: rfnsa, alt: "Rfnsa logo empresa" },
  { id: 2, logo: msdigital, alt: "msdigital logo empresa" },
  { id: 3, logo: brdigital, alt: "brdigital logo empresa" },
  { id: 4, logo: alt, alt: "alt logo empresa" },
  { id: 5, logo: oggi, alt: "Oggi logo empresa" },
  { id: 6, logo: transpetro, alt: "Transpetro logo empresa" },
  { id: 7, logo: acelera, alt: "Acelera logo empresa" },
  { id: 8, logo: alfa, alt: "Alfa logo empresa" },
  { id: 9, logo: fenix, alt: "Fenix logo empresa" },
  { id: 10, logo: claro, alt: "Claro logo empresa" },
  { id: 11, logo: sim, alt: "Sim logo empresa" },
  { id: 12, logo: conbras, alt: "Conbras logo empresa" },
  { id: 13, logo: vett, alt: "Vett logo empresa" },
  { id: 14, logo: vivo, alt: "Vivo logo empresa" },
  { id: 15, logo: tim, alt: "Tim logo empresa" },
  { id: 16, logo: elo, alt: "Elo logo empresa" },
  { id: 17, logo: glamour, alt: "Glamour logo empresa" },
  { id: 18, logo: nesic, alt: "Nesic logo empresa" },
  { id: 19, logo: vital, alt: "Vital logo empresa" },
  { id: 20, logo: Tecpar, alt: "Tecpar logo empresa" },
];

const Partners = () => {
  return (
    <div className="partners">
      <h1>Clientes</h1>
      <ul>
        {partnersData.map(partner => (
          <li key={partner.id}>
            <img src={partner.logo} alt={partner.alt} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Partners;
